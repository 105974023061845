/*
Template Name: Velzon - Admin & Dashboard Template
Author: Themesbrand
Website: https://themesbrand.com/
Contact: support@themesbrand.com
File: Custom Css File
*/

.logo img,
.logo svg,
.logo-lg img,
.logo-lg svg {
  vertical-align: middle;
  height: 54px !important;
  width: 100%;
}

.navbar-menu {
  background-color:  #03a551 !important
}

.navbar-menu .navbar-nav .nav-link {
  color: #fff !important;
}

.pagination-wrap.hstack.gap-2 ul li {
  margin-left: 0.3rem;
  border-radius: 0.25rem !important;
  border: 1px solid var(--vz-border-color);
  background-color: var(--vz-card-bg-custom);
  color: var(--vz-link-color);
  padding: 5px 14px;
  list-style: none;
}

.pagination-wrap.hstack.gap-2 ul {
  display: flex;
}

.pagination-wrap.hstack.gap-2 ul li.selected {
  background-color: var(--vz-link-color);
  color: #fff;
}

.action .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  font-size: 15px;
  line-height: 15px;
  content: "" !important;
  font-family: "Material Design Icons";
}

.action .dropdown-toggle {
  color: #3577f1;
  background-color: rgba(53, 119, 241, .1) !important;
  border-color: transparent !important;
}

.LYwHa {
  overflow-x: initial !important;
  overflow-y: initial !important;
}

a.nav-link.menu-link.collapsed:after {
  display: block;
  content: "\f0142";
  font-family: "Material Design Icons";
  margin-left: auto;
  -webkit-transition: -webkit-transform .2s;
  transition: -webkit-transform .2s;
  transition: transform .2s;
  transition: transform .2s, -webkit-transform .2s;
  font-size: 1.05rem;
  position: absolute;
  right: 18px;
  color: var(--vz-vertical-menu-title-color);
}

li.nav-item.is-expanded a.nav-link.menu-link.collapsed:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  color: var(--vz-vertical-menu-item-active-color);
}

.dl dt {
  float: left;
  width: 45%;
  padding: 0 !important;
  overflow: hidden;
  clear: left;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  /* font-size: 13px; */
}

.dl dd {
  margin-left: 50% !important;
  /* font-size: 13px; */
}

.dl dd {
  margin-left: 50% !important;
  /* font-size: 13px; */
}

.table>:not(caption)>*>* {
  padding: 0.1rem 1px 0.5rem !important;
}

.logo img,
.logo svg,
.logo-lg img,
.logo-lg svg {
  vertical-align: middle;
  height: 54px !important;
  width: auto !important;
}

.accordion-item {
  color: var(--vz-accordion-color);
  background-color: #e9ebec4f !important;
  border: var(--vz-accordion-border-width) solid var(--vz-accordion-border-color);
}

.page-content-client {
  padding: calc(1px + 1.5rem) calc(1.5rem* 0.5) 60px calc(1.5rem* 0.5);
}

.main-content-client {
  margin-left: 0;
}

.footer-client {
  bottom: 0;
  padding: 20px calc(1.5rem* 0.5);
  position: absolute;
  right: 0;
  color: var(--vz-footer-color);
  left: 0;
  height: 60px;
  background-color: var(--vz-footer-bg);
}


.simplebar-wrapper .simplebar-content .navbar-menu .navbar-nav .nav-link i {

  margin-bottom: 0px !important;
  
}