footer {
    /* background-image: url(../images/dark-pattern.jpg); */
    background-repeat: repeat;
    background-position: top left;
    background: #13293d;
}

footer {
    color: #6e7173;
    font-weight: 300;
    padding: 21px 0px;
    text-align: left;
}

footer h4,
footer .strong {
    color: #acadaf;
    font-weight: 500;
    font-size: 18px;
}

footer h4 {
    margin-bottom: 15px;
}

footer .highlighted {
    color: #FFC107;
    font-size: 16px;
    font-weight: 400;
    vertical-align: -1px;
}

footer ul.two-columns li {
    width: 100%;
    float: left;
}

footer ul.two-columns::after {
    content: "";
    display: table;
    clear: both;
}

footer ul {
    padding: 0px;
}

footer ul li {
    list-style-type: none;
    padding: 4px 0px;
}

footer ul li a {
    color: #6e7173;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

footer ul li a:hover {
    color: #828284;
    padding-left: 10px;
}

.foot-logo {}

.foot-logo img {
    padding-bottom: 20px;
    width: 175px;
}

.foot-sec2 {
    padding-top: 50px;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.foot-social {}

.foot-social ul {
    padding: 0px;
}

.foot-social ul li {
    list-style-type: none;
    float: left;
    padding-right: 10px;
}

.foot-social ul li a img {
    width: 32px;
    height: 32px;
}

.foot-social ul li a:hover {
    padding-left: 0px;
}

.copy {
    background: #131925;
    position: relative;
    overflow: hidden;
    padding: 5px;
}

.copy p {
    margin-bottom: 0px;
    text-align: center;
    padding: 8px;
}

.copy p a {
    color: #636363;
}

.foot-sec {
    position: relative;
    overflow: hidden;
    margin-top: 55px;
}

.col1 {
    width: 10%;
    float: left;
    padding: 15px;
}

.col15 {
    width: 15%;
    float: left;
    padding: 15px;
}

.col2 {
    width: 20%;
    float: left;
    padding: 15px;
}

.col25 {
    width: 25%;
    float: left;
    padding: 15px;
}

.col3 {
    width: 30%;
    float: left;
    padding: 15px;
}

.col35 {
    width: 35%;
    float: left;
    padding: 15px;
}

.col4 {
    width: 33%;
    float: left;
    padding: 15px;
}

.col45 {
    width: 45%;
    float: left;
    padding: 15px;
}

.col5 {
    width: 50%;
    float: left;
    padding: 15px;
}

.col55 {
    width: 55%;
    float: left;
    padding: 15px;
}

.col6 {
    width: 60%;
    float: left;
    padding: 15px;
}

.col65 {
    width: 65%;
    float: left;
    padding: 15px;
}

.col7 {
    width: 70%;
    float: left;
    padding: 15px;
}

.col75 {
    width: 75%;
    float: left;
    padding: 15px;
}

.col8 {
    width: 80%;
    float: left;
    padding: 15px;
}

.col85 {
    width: 85%;
    float: left;
    padding: 15px;
}

.col9 {
    width: 90%;
    float: left;
    padding: 15px;
}

.col95 {
    width: 95%;
    float: left;
    padding: 15px;
}

.land-3-top-full {
    background: #fff;
}

[class~=land-3-top-contact] ul li,
[class~=land-3-top-contact] ul li:before {
    color: #1a2a38;
}

footer ul li a {
    color: #fff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

footer {
    /* background-image: url(../images/dark-pattern.jpg); */
    color: #fff;

    background-repeat: repeat;
    background-position: top left;
    background: hsl(148.28deg 91.81% 33.53%)
}

footer h4,
footer .strong {
    color: #fff;
    font-weight: 500;
    font-size: 18px;
}

[class~=land-1-foot-text] p {
    color: #fff;
}

.foot-logo img {
    padding-bottom: 0px;
    width: 45%;
}

[class~=test-box]:hover p {
    color: #fff;
}

[class~=test-box]:hover {
    background: #03a551;
    color: #fff;
}

[class~=test-box]:hover {
    box-shadow: 0px 3.75pt 20px -0.083333333in rgb(3 165 81);
}

.roboto-thin {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.roboto-light {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.roboto-regular {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.roboto-bold {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.roboto-black {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.roboto-thin-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.roboto-light-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.roboto-regular-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.roboto-medium-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.roboto-bold-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.roboto-black-italic {
    font-family: "Roboto", sans-serif;
    font-weight: 900;
    font-style: italic;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
b,
a {
    font-family: 'Roboto', sans-serif;
}

.land-3-banner-conte h4 {
    font-weight: 400;
}

.land-3-banner-conte h4 {
    font-size: 25px;
}

[class~=land-3-banner-conte] p {
    font-size: 16px;
    margin-bottom: 30px;
}

.test-box {
    height: 290px;
}

[class~=land-com-form] form ul li:before {
    margin-top: 3pt;
}


.accordion {
    /* width: 700px; */
    margin: 100px auto;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.item {
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
    padding: 20px 24px;
    padding-right: 48px;
    cursor: pointer;
    border-top: 4px solid #fff;
    border-bottom: 4px solid #fff;

    /* display: grid; */
    grid-template-columns: auto 1fr auto;
    column-gap: 24px;
    row-gap: 32px;
    align-items: center;
}

.number {
    font-size: 24px;
    font-weight: 500;
    color: #ced4da;
}

.title,
.icon {
    font-size: 24px;
    font-weight: 500;
}

.content-box {
    grid-column: 2 / -1;
    padding-bottom: 16px;
    line-height: 1.6;
}

.content-box ul {
    color: #868e96;
    margin-left: 16px;
    margin-top: 16px;

    display: flex;
    flex-direction: column;
    gap: 12px;
}

/* OPEN STATE */
.open {
    border-top: 4px solid #087f5b;
}

.open .number,
.open .text {
    color: #087f5b;
}

.item img {
    width: 20%;
}

[class~=land-3-banner-conte] p {
    font-size: 22px;
    margin-bottom: 30px;
    line-height: 32px;
}

[class~=land-3-banner-conte] h1 {
    font-size: 3rem;
    font-weight: 900;
}

[class~=land-3-banner-conte] {
    width: 55%;
}

[class~=land-3-banner-form],
[class~=land-3-dwn-img] {
    width: 45%;
}

.container.land-container.carosels {
    width: 1320px;
}

/* .land-com-form {

    height: 85%;
} */

.contact__form .keybord {
    width: 100%;
    /* padding: 2%; */
    /* align-items: center; */
    margin-bottom: 15px;
    left: 0%;
    top: 10px;
}

.contact__form .keybord .buttons button {
    background: rgba(0, 17, 44, 0.05);
    border: 1px solid rgba(179, 179, 179, 0.3);

    margin: 1px;
    color: #5f5e5e;
    font-size: 18px !important;
    font-family: 'Roboto', sans-serif;
    height: 70px;
    margin: 5px 5px 5px 5px;
    width: 80px;
    cursor: pointer;
    position: relative;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}

.contact__form .keybord .buttons button:hover {
    background: #03a551;
    border: 1px solid #03a551;
    /* -webkit-border-radius: 3px !important !important; */
    /* -moz-border-radius: 3px !important !important;
    -ms-border-radius: 3px !important !important;
    -o-border-radius: 3px !important !important;
    border-radius: 3px !important !important; */
    margin: 1px;
    color: #fff;
    font-size: 18px !important;
    font-family: 'Roboto', sans-serif;
    height: 70px;
    margin: 5px 5px 5px 5px;
    width: 80px;
    cursor: pointer;
    position: relative;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
}



.col-md-6.land-3-agen-right iframe {
    display: inline;
}

:before,
:after {
    font-family: remixicon !important;
}

.land-3-top-contact ul li:nth-child(2):before {
    content: "mail";
    font-family: 'Material Icons' !important;

}

[class~=land-3-top-contact] ul li:nth-child(1):before {
    content: "local_phone";
    font-family: 'Material Icons' !important;

}

.footers {
    background-image: url('../images/footer_background.png');
}

section.land-full.land-3-cus-full.mt-5 {
    background: #f8f8f8;
}

.keybord .buttons {
    justify-content: center;
    display: flex;
    grid-gap: 10px;
    gap: 13px;
    flex-wrap: wrap;
}

.land-event-date button {
    background-color: #b32120 !important;
    border: #b32120;
    /* padding: 2%; */
    color: #fff;
}

.land-event-date button:hover {

    background-color: #03a551 !important;
    border: #03a551;
    /* padding: 2%; */
    color: #fff;
}

.btn1 {
    background-color: #03a551 !important;
    border: #03a551;

    color: #fff;
}

.btn1:hover {
    background-color: #03a551 !important;
    border: #03a551;

    color: #fff !important;
}

[class~=land-3-banner-full]:before {
    background: hsl(199deg 19% 20% / 70%);
}

.form-label {
    font-family: 'Roboto', sans-serif;
}

[class~=land-3-banner-conte] p {
    border-bottom-style: none;
}

/* .mes-btn {
    align-items: baseline;
    justify-content: center;

} */

.mes-btn .btn1 {
    display: grid;
    left: 79%;
}

footer ul li,
footer p,
footer a {

    font-family: 'Roboto', sans-serif;
}

footer ul li a:hover {
    color: #fff;
    padding-left: 10px;
}

.clearbtn a {
    left: '32%';
    border: none;
    color: #29363b;
}

.land-3-banners {
    text-align: center;
}

.land-3-banners h1 {
    color: #fff;
}

@media screen and (max-width: 576px) {
    .container.land-container.carosels {
        width: 576px;
    }

    [class~=land-3-banner-conte] {
        width: 576px;
    }

    [class~=land-3-banner-form],
    [class~=land-3-dwn-img] {
        width: 576px;
    }

    .contact__form {
        width: 263px;
    }

    [class~=land-3-banner-conte] {
        width: 350px;
        padding: 35px;
    }

    [class~=land-3-banner-conte] p {
        font-size: 16px;
        margin-bottom: 30px;
        line-height: 25px;
    }

    [class~=land-3-banner-conte] h1 {
        font-size: 1rem;
        font-weight: 900;
        line-height: 30px;
    }

    .contact__form .keybord .buttons button {
        background: rgba(0, 17, 44, 0.05);
        border: 1px solid rgba(179, 179, 179, 0.3);
        margin: 1px;
        color: #5f5e5e;
        font-size: 18px !important;
        font-family: 'Roboto', sans-serif;
        height: 31px !important;
        height: 69px;
        width: 31px !important;
        width: 37px;
        cursor: pointer;
        position: relative;
        -o-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    }

    .keybord .buttons {
        justify-content: center;
        display: flex;
        /* grid-gap: 10px; */
        grid-gap: 13px;
        gap: 13px;
        flex-wrap: wrap;
    }

    .contact__form .keybord {
        width: 99%;
        /* padding: 2%; */
        /* align-items: center; */
        margin-bottom: 15px;
        left: 0%;
        top: 10px;
    }

    .land-com-form {
        width: 318px;
        margin-left: 1px;
    }

    .clearbtn a {
        left: 0;
        border: none;
        color: #29363b;
    }

    .mes-btn {
        flex-direction: column;

    }

    .mes-btn .btn1 {
        display: grid;
        left: 26%;
        top: 16px;
    }

    .clearbtn {
        align-self: self-end;
        position: absolute;
        left: -22%;
        bottom: 56px;


    }

    .footers {
        /* background-image: url(/static/media/footer_background.8f5fc75e.png); */
        background-repeat: no-repeat;
        background-size: cover;
    }

    footer h4 {
        margin-top: 22px;
        text-align: center;
    }

    .foot-logo {
        text-align: center;
    }

    footer.container-fluid.land-full.footers.mt-5 {
        text-align: center;
        line-height: 18px;
    }

    .land-3-dwn-right,
    [class~=land-3-cus-box],
    .land-3-top-logo,
    [class~=land-3-dwn-img] {
        width: 48%;
    }

    [class~=land-3-top-logo] a img {
        height: auto;
    }

    [class~=land-3-top-logo] a img {
        margin-top: -7px;
    }

    [class~=land-3-top-logo] a img {
        width: 56pt;
    }

    .land-container {
        width: 100%;
    }

    div#root {
        overflow-x: hidden;
    }

    [class~=land-3-top-logo] a img {
        margin-left: 104%;

    }
}

@media screen and (min-width: 768px) {}


body {
    background-color: #aec9bb17;
}

.land-event-date.desc {
    display: flex;
    justify-content: center;
}

.land-event-date button.btn.btn-success {
    background: #03a551 !important;
}

.dl dt {
    float: left;
    width: 45%;
    padding: 0 !important;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* font-size: 13px; */
    line-height: 27 px;
}

.dl dd {
    margin-left: 50% !important;
    /* font-size: 13px; */
}

.dl dd {
    margin-left: 50% !important;
    /* font-size: 13px; */
}

.land-event-date.desc button {
    margin: 1%;
}

.demande li {
    list-style-type: disc !important;
}

#preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #03a551 !important;
    z-index: 9999;
}

.MuiBox-root.MuiBox-root-6 {
    justify-content: space-between;
    display: flex;

}

.MuiStepIcon-root.MuiStepIcon-active {
    color: #03a551 !important;

}

.MuiStepIcon-root {
    color: #b32120 !important;
}

.MuiStepIcon-root.MuiStepIcon-completed {
    color: #03a551 !important;
}

.prive .form-check.form-check-warning {
    justify-content: space-between;
    box-shadow: 0 1px 2px rgba(56, 65, 74, .15);
    background: #f3f3fa;
    margin-top: 2px;
}

.prive .privechec {
    align-self: center;
    display: grid;
}

.land-event-date .btn1 {
    background-color: #03a551 !important;
    border: #03a551;
    /* padding: 2%; */
    color: #fff;
}

.dropdown.d-inline-block.dropdown .btn-soft-secondary:hover,
.dropdown.d-inline-block.dropdown .btn-soft-secondary:active {

    background-color: transparent !important;
    border-color: transparent;
}

.user .card-header {
    background: #fff;
}

[class~=land-3-top-logo] a img {
    height: auto;
}

[class~=land-3-top-logo] a img {
    margin-top: -7px;
}

[class~=land-3-top-logo] a img {
    width: 56pt;
    object-fit: scale-down;
}

#layout-wrapper .logo img {
    vertical-align: middle;
    height: auto !important;
    width: 27% !important;
    margin-top: 7px;
}

.recapt {
    position: relative;
    /* border: 1px solid; */
    width: 64%;
    left: 22%;
    top: 17px;
    margin-bottom: 7px;
    right: auto;
}

.mes-btn.text-center.d-flexmes-btn.row {
    overflow: initial !important;
}

iframe {
    display: none;
}

[class~=land-3-top-contact] ul li:before {
    margin-top: -4px;
}

.contact__form .form-control {
    display: block;
    width: 100%;
    padding: .375rem .75rem;
    font-size: .9rem;
    font-weight: 100;
    line-height: 1.5;
    color: var(--bs-body-color);
    appearance: none;
    background-color: var(--bs-body-bg);
    /* background-clip: padding-box; */
    /* border: var(--bs-border-width) solid var(--bs-border-color); */
    border-radius: var(--bs-border-radius);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

iframe {
    position: initial !important;
    top: 0px;
    left: 0px;
    width: 100% !important;
    height: 100% !important;
    border: none;
    z-index: 2147483647;
    display: grid;
}

.land-com-form iframe {
    display: block;
    top: 0px;
    left: 0px;
    width: 100% !important;
    height: 100% !important;
}

.footers a:hover {
    color: #fdfc12;
}

.navbar-menu .navbar-nav .nav-link[data-bs-toggle="collapse"]:after {
    font-family: "Material Design Icons" !important;
}

.document iframe {
    display: block !important;
    height: 500px;
    width: 100%;
}

.profile-timeline.renouvellement .accordion-item.border-0 {
    background-color: #fff !important;
}

.renouvellement .accordion-item::before {
    border-left: none !important;
}

.renouvellement .accordion {
    margin: 40px auto;
}

.avatar-title.bg-light.text-secondary.rounded.fs-24 i:before {
    top: 0px;
    left: 0px;
}

.video .ratio.ratio-4x3 {
    height: 350px;
}

.col-md-6.py-4.doc a:hover {
    color: #b32120;
    line-height: inherit;
    font-size: 1rem;
}

.col-md-6.py-4.doc a {
    color: #03a551;
    line-height: inherit;
    font-size: 1rem;
}

.shepherd-element {

    max-width: 500px !important;

}

footer.shepherd-footer {
    background: transparent;
}

.sect1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: auto;
    background: white;
    padding: 0;
    border-radius: 2vmin;
}

.sect1 h2 {
    font-size: 16px;
    line-height: 100%;
    font-weight: 600;
    color: black;
    text-align: center;
}

.sect1 p {
    font-size: 14px;
    line-height: 150%;
    font-weight: 400;
    color: black;
    padding-left: 20px;
    border-left: 2px solid navy;
    margin: 16px 0;
    width: 100%;
}

.sect1 a {
    font-size: 3vmin;
    background-color: navy;
    color: white;
    font-weight: 500;
    letter-spacing: 1px;
    padding: 2vmin 4vmin;
    border-radius: 1.5vmin;
}


.sect1::before {
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background: white;
    border-radius: 100%;
    filter: drop-shadow(15vmin 15vmin 22vmin navy);
    transform-box: fill-box;
    -webkit-transform-box: fill-box;
    transform-origin: center;
    -webkit-transform-origin: center;
    content: "";
    z-index: -10;
    animation: shadowRotate 5s linear infinite;
}

/* /* @keyframes shadowRotate {
    to {
      transform: rotateZ(360deg);
    } 
  } */

.sect1 dl.dl {
    width: 100%;
}

.sect1 i {
    font-size: 26px;
}

.shepherd-title {
    color: #b44c36;
}

.shepherd-has-title .shepherd-content .shepherd-header {
    background-color: #fde8e4;
}

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.btn.btn-danger {
    background-color: #b32120;
    color: #fff;
}

.MuiButton-label {
    display: block !important;
}

/*Pricing tables*/
.pricing-table .pricing-list li {
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.pricing-table .card {
    margin-top: 2rem;
    /* background-color: #00e5ff; */
    transition: all .25s ease-out;
}

.pricing-table .card .card-body {
    /* padding: 4.25rem 2rem; */
}

.pricing-table .card .h1 {
    font-size: 5rem;
    font-weight: 400;
}

.pricing-table .card p {
    text-transform: uppercase;
    font-weight: 700;
}

.pricing-table .card:hover .h1 {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
    transition: all .25s ease-out;
}

.ptives i :after,
:before {
    position: relative;
}

#land-3-back {
    background-image: url('../carte.png');

    /* Répéter l'image de fond horizontalement et verticalement */
    background-repeat: no-repeat;
    position: relative;
    top: 1px;
    /* Centrer l'image de fond horizontalement et verticalement */
    background-position: top center;

    /* Fixer l'image de fond lorsque l'élément définit une position de défilement */
    background-attachment: initial;

    /* Taille de l'image de fond - auto signifie la taille réelle de l'image */
    background-size: cover;

    /* Autres propriétés facultatives */

}

.ratio:before {
    content: "";
    display: block;
    padding-top: 0 !important;
}

@media screen and (max-width: 1400px) {
    #land-3-back {
        background-image: url('../carte.png');

        /* Répéter l'image de fond horizontalement et verticalement */
        background-repeat: no-repeat;
        position: relative;
        top: 1px;
        /* Centrer l'image de fond horizontalement et verticalement */
        background-position: top center;

        /* Fixer l'image de fond lorsque l'élément définit une position de défilement */
        background-attachment: initial;

        /* Taille de l'image de fond - auto signifie la taille réelle de l'image */
        background-size: contain;

        /* Autres propriétés facultatives */

    }
}

.simplebar-wrapper .simplebar-content .navbar-menu .navbar-nav .nav-link i {

    margin-bottom: 0px !important;

}

[class~=land-3-top-contact] ul li {
    display: flex;
    /* align-items: center; */
    padding-bottom: 4px;
}
iframe#webpack-dev-server-client-overlay {
    display: none;
}
@media screen and (max-width: 524px) {
    .MuiStepper-root {
        display: contents !important;
        
    }
    .MuiStep-horizontal {
       
        padding: 2%;
    }
    .MuiButton-root {
        color: rgba(0, 0, 0, 0.87);
        padding: 8px 4px !important;
        font-size: 0.875rem;
        min-width: 69px;
    }
    .MuiBox-root.MuiBox-root-2 {
        margin-bottom: 15px;
        border-block-end: 1px solid;
    }
    .recapt {
        position: relative;
        border: 0;
        width: 0;
        left: 0;
        top: 17px;
        margin-bottom: 7px;
        right: auto;
    }
    .land-com-form {
        width: 66%;
        margin: 8px;
        margin-right: 7px;
    }
    [class~=land-3-banner-form], [class~=land-3-dwn-img] {
        width: 576px;
    }
    .contact__form {
        width: 100%;
    }
    .land-3-top-logo, [class~=land-3-dwn-img] {
        width: 44%;
    }
    .land-event-date {
        margin-right: 10%;
        margin-left: 17%;
        display: grid !important;
        justify-content: center;
    }
    [class~=land-3-banner-conte] {
        width: 70%;
        padding: 16px;
    }
}

.navbar-menu .navbar-nav .nav-link.active {
    color: #fff;
    background: #029448 !important;
}
.MuiTypography-body2{
    font-size: 0.75rem !important;
}
.document iframe {
    display: block !important;
    height: 43rem !important;
    width: 100% !important;
}

.profile-wid-bg::before{
    background: linear-gradient(to top, #171e32, #03a551) !important;
}
#layout-wrapper {
    overflow-x: hidden;
}
.land-event-date .btn1:hover {
    background: #b32120 !important;
}
button.btn.btn1:hover {
    background: #b32120 !important;
}