.payment-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  flex-direction: column;
}

.payment-container .payment-header {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
  height: 200px;
  padding-bottom: 20px;
}

.payment-container .payment-title {
  font-size: 12px;
  font-weight: 600;
  color: #333
}

.payment-container .payment-header {  
  display: flex;
  flex-direction: row;
  gap: 100px;
  margin: 0 auto;
}

.payment-container .payment-header .mean-box {
    height: 120px;
    width: 120px;
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;
    border: 1px solid #f1f1f1;
    border-radius: 5px;
    gap: 5px;
    padding: 5px;
}

.payment-container .payment-header .mean-box:hover {
  cursor: pointer;
  border: 2px solid #009645;
}

.payment-container .payment-header .mean-box.active {
  border: 2px solid #009645;
}

.mean-box img {
  width: 70px;
  height: 70px;
}
/* 
              <form action="" className="payment-form">

<div className="payment-input-group">
<label htmlFor="">
  Numéro de téléphone
</label>
<input type="text" placeholder="Numéro de téléphone" />
</div>
</form> */

.payment-container .payment-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
}

.payment-container .payment-form .payment-input-group {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin: 0 auto;
  min-width: 400px;
}

.payment-container .payment-form .payment-input-group label {
  font-size: 12px;
  font-weight: 600;
  color: #333;
}

.payment-container .payment-form .payment-input-group input {
  height: 40px;
  border: 1px solid gray;
  border-radius: 5px;
  padding: 5px;
}

.payment-container .payment-form .payment-input-group input:focus {
  border: 2px solid #009645;
}
.payment-container .payment-form .payment-input-group input[type="submit"] {
  background-color: #009645;
  color: white;
  font-size: 12px;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  padding: 5px;
}

